import React from "react";

const Tour3d = ({ link, onLoad }) => {
  return (
    <iframe
      title="tour3dViewer"
      width="853"
      height="480"
      src={link}
      frameBorder="0"
      allowFullScreen
      allow="xr-spatial-tracking"
      onLoad={onLoad}
    ></iframe>
  );
};

export default Tour3d;
