import { useState } from "react";
import Tour3d from "./Tour3d";

const Table = ({ data }) => {
  const [show3dTour, setShow3dTour] = useState({
    link: "",
    show: false,
  });
  const [showFloorPlan, setShowFloorPlan] = useState({
    link: "",
    show: false,
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleClickOnView = (link) => {
    setShow3dTour({
      link: link,
      show: true,
    });
    setIsLoading(true); // Set loading state to true when showing the modal
  };
  const handleClickOnFloorPlan = (link) => {
    setShowFloorPlan({
      link: link,
      show: true,
    });
    setIsLoading(true); // Set loading state to true when showing the modal
  };

  const closeModal = () => {
    setShow3dTour({
      link: "",
      show: false,
    });
    setShowFloorPlan({
      link: "",
      show: false,
    });
    setIsLoading(false); // Reset loading state
  };

  const handleIframeLoad = () => {
    setIsLoading(false); // Set loading state to false when iframe loads
  };

  return (
    <>
      <div className="table">
        <div className="inner">
          <div className="table-row table-head">
            <div className="residence cell head">Residence</div>
            <div className="bed-bath cell head">Bed/Bath</div>
            <div className="outdoor cell head">Outdoor</div>
            <div className="price cell head">Price</div>
            <div className="tour3d cell head">3D Tour</div>
            <div className="tour3d cell head">Floor Plans</div>
          </div>
          {data.map((subtable, index) => {
            return (
              <div className={"subtable index" + index} key={index}>
                <div className="table-row table-head mobile">
                  <div className="cell head">{subtable[0].type}</div>
                </div>
                {subtable.map((row, rowIndex) => {
                  return (
                    <div className="table-row" key={rowIndex}>
                      <div className="residence cell">
                        <span className="mobile">Residence</span>
                        {row.residence}
                      </div>
                      <div className="bed-bath cell">
                        <span className="label mobile">Bed/Bath</span>
                        {row.bedBath}
                      </div>
                      <div className="outdoor cell">
                        <span className="label mobile">Outdoor</span>
                        {row.outdoor}
                      </div>
                      <div className="price cell">
                        <span className="label mobile">Price</span>${row.price}
                      </div>
                      <div
                        className={
                          row.tour3d === "View" ? "view tour3d cell" : "tour3d cell"
                        }
                        onClick={
                          row.tour3d === "View"
                            ? () => handleClickOnView(row.tour3dLink)
                            : undefined
                        }
                      >
                        <span className="label mobile">3D Tour</span>
                        {row.tour3d}
                      </div>
                      <div 
                        className={
                          row.floorPlan ? "view floorplan cell" : "floorplan cell"
                        }
                        onClick={
                          row.floorPlan
                            ? () => handleClickOnFloorPlan(row.floorPlan)
                            : undefined
                        }
                      >
                        <span className="label mobile">Floor Plan</span>
                        {row.floorPlan ? "View" : "Coming Soon"}
                        
                      </div>
                    </div>
                    
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
      {(show3dTour.show || showFloorPlan.show) && (
        <div className="show3d-modal">
            <div className="inner">
                <div className="wrapper">
                    <svg
                    className="close-show3d-modal"
                    onClick={closeModal}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    >
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g
                        id="SVGRepo_tracerCarrier"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                        <path
                        d="M19 5L4.99998 19M5.00001 5L19 19"
                        stroke="#ffffff"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        ></path>
                    </g>
                    </svg>
                    {showFloorPlan.show && <img src={showFloorPlan.link} alt="floorplan" />}
                    {(show3dTour.show && isLoading) && <div className="loader"></div>}
                    {show3dTour.show && <Tour3d link={show3dTour.link} onLoad={handleIframeLoad} />}
                </div>
            </div>
        </div>
      )}
    </>
  );
};

export default Table;
