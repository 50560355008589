import BannerContent from '../components/BannerContent';
import Table from '../components/Table';
import bannerImage from '../assets/images/availabilities/Availabilities-Hero-Banner.jpg'
import Availability4f from '../assets/images/availabilities/4f.jpg'
import Availability5h from '../assets/images/availabilities/5h.jpg'
import Availability3c from '../assets/images/availabilities/3c.jpg'
import Availability6f from '../assets/images/availabilities/6f.png'
import Hero from '../components/Hero';
import QuickNav from '../components/QuickNav';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';
import ScrollToTop from '../components/ScrollToTop';

const Availabilities = () => {
    let TableData = [
        // [
        //     {
        //         residence : "4B",
        //         type : "1 Bed",
        //         bedBath : "1 Bed | 2 Bath + Home Office",
        //         outdoor : "Balcony",
        //         price : "3,250.00",
        //         tour3d : "View",
        //         tour3dLink : "https://my.matterport.com/show/?m=Ppadxy5T58b",
        //         floorPlan : AvailabilityB
        //     },
        //     {
        //         residence : "5B",
        //         type : "1 Bed",
        //         bedBath : "1 Bed | 2 Bath + Home Office",
        //         outdoor : "Balcony",
        //         price : "3,250.00",
        //         tour3d : "View",
        //         tour3dLink : "https://my.matterport.com/show/?m=Ppadxy5T58b",
        //         floorPlan : AvailabilityB
        //     },
        //     {
        //         residence : "6B",
        //         type : "1 Bed",
        //         bedBath : "1 Bed | 2 Bath + Home Office",
        //         outdoor : "Balcony",
        //         price : "3,275.00",
        //         tour3d : "View",
        //         tour3dLink : "https://my.matterport.com/show/?m=Ppadxy5T58b",
        //         floorPlan : AvailabilityB
        //     },
        // ],
        [
            {
                residence : "3C",
                type : "2 Bed",
                bedBath : "2 Bed | 2 Bath",
                outdoor : "Balcony",
                price : "3,275.00",
                tour3d : "View",
                tour3dLink : "https://my.matterport.com/show/?m=CVAQDwvp7dP",
                floorPlan : Availability3c
            },
            {
                residence : "4F",
                type : "2 Bed",
                bedBath : "2 Bed | 2 Bath + Home Office",
                outdoor : "Balcony",
                price : "3,687.50",
                tour3d : "View",
                tour3dLink : "https://my.matterport.com/show/?m=fxRJCZsrB5e",
                floorPlan : Availability4f
            },
            {
                residence : "5H",
                type : "2 Bed",
                bedBath : "2 Bed | 2 Bath",
                outdoor : "-",
                price : "3,225.00",
                tour3d : "View",
                tour3dLink : "https://my.matterport.com/show/?m=kMkZB8pjBJm",
                floorPlan : Availability5h
            },
        ],
        [
            {
                residence : "7F",
                type : "3 Bed",
                bedBath : "3 Bed | 2 Bath",
                outdoor : "Terrace",
                price : "3,950.00",
                tour3d : "Coming Soon",
                tour3dLink : "",
                floorPlan : ""
            },
            {
                residence : "6F",
                type : "3 Bed",
                bedBath : "3 Bed | 2 Bath",
                outdoor : "Balcony",
                price : "3,750.00",
                tour3d : "View",
                tour3dLink : "https://my.matterport.com/show/?m=fxRJCZsrB5e",
                floorPlan : Availability6f
            }
        ]
    ]
    let heroData = {
        backgroundImage : bannerImage,
        heading : "",
        description: ""
    }
    let bannerContentData = {
        heading : "Check Availability",
        description : "Dive into luxury living at Ocean 1946 – where every corner is crafted for comfort and style. Explore our range of exquisite units and floor plans, tailored to suit your New York City dreams. Discover the perfect chapter for your next adventure at Ocean 1946."
    }
    let QuickLinks = {
        prev : {
            title : "Contact",
            url : "/contact"
        },
        next : {
            title : "Residences",
            url : "/residences"
        }
    }
    return ( 
    <>
        <ScrollToTop />
            <Helmet>
                <title>Available Luxury Apartments at 1946 Ocean, Midwood, Brooklyn</title>
                <meta name="description" content="Explore available luxury One and Two-bedroom 
apartments at 1946 Ocean. Live in the heart of Midwood, Brooklyn, with 
sophisticated amenities and elegant living spaces." />
            </Helmet>
        <Header/>
        <Hero data={heroData} />
        <BannerContent data={bannerContentData} />
        <Table data={TableData}/>
        <QuickNav links={QuickLinks}/>
        <Footer/>
    </> 
    );
}
 
export default Availabilities;